import PropTypes from 'prop-types'
import useAuthority from 'utils/hooks/useAuthority'

const AuthorityCheck = (props) => {
    const { userAuthority = '', authority = [], children } = props

    // Convert userAuthority string to an array if it's a string
    const userAuthorityArray = Array.isArray(userAuthority)
        ? userAuthority
        : userAuthority.split(',').map((authority) => authority.trim())

    // Convert authority string to an array if it's a string
    const authorityArray = Array.isArray(authority)
        ? authority
        : authority.split(',').map((authority) => authority.trim())

    const roleMatched = useAuthority(userAuthorityArray, authorityArray)

    return roleMatched ? children : null
}

AuthorityCheck.propTypes = {
    userAuthority: PropTypes.string,
    authority: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
    ]),
}

export default AuthorityCheck
