import { storage } from './storage'
import { getToken } from 'configs/token'
export const TOKEN_TYPE = 'Bearer '
export const REQUEST_HEADER_AUTH_KEY = 'Authorization'

function Util() {
    this.endPoint = 'https://cbnprodapsenairaussd.azurewebsites.net/api/'
    this.baseUrl = 'https://liteapi.enaira.gov.ng/api/v1/'
    this.socketUrl = 'https://liteapi.enaira.gov.ng/'
    this.speed = 'https://enairaspeedwallet.azurewebsites.net/api/v1/'
    this.camel1Prod = 'https://caml1.enaira.gov.ng/camluam/api/v1/'
    this.camelSyProd = 'https://caml1.enaira.gov.ng/camlsystem/api/v1/'
    this.camel2Prod = 'https://caml2.enaira.gov.ng/camlcompliance/api/v1/'
    this.camel3Prod = 'https://caml3.enaira.gov.ng/enairapayapp/api/v1/'
    this.camel4Prod = 'https://caml3.enaira.gov.ng/tnxandcharges/api/v1/'
    this.camel5Prod = 'https://app.enaira.com/'
    this.camel6Prod = 'https://caml3.enaira.gov.ng/enairapay/api/v1/'
    this.camel7Prod = ' https://caml3.enaira.gov.ng/nipservice/api/v1/'
    this.camel3dProd = 'https://caml3.enaira.gov.ng/camluam/api/v1/'
    this.getHeaders = () => {
        return {
            'Content-Type': 'application/json',
            Accept: 'application/json; charset=utf-8',
        }
    }
    this.getAuthorizedHeaders = async () => {
        const userToken = storage.get('userToken')
        const token = await getToken()
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'x-wallet-token': `${userToken}`,
        }
    }

    this.getAuthorizedFileHeaders = () => {
        return {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json; charset=utf-8',
            Authorization: `Bearer ${storage.get('userToken')}`,
        }
    }
}
const util = new Util()
export { util }
