import organisationLogin from 'services/organisation/organisation.service'
const saveTokenData = (token) => {
    const tokenData = {
        token: token,
        lastSaved: Date.now(),
    }
    localStorage.setItem('orgToken', JSON.stringify(tokenData))
}


const dateDifference = (first, second) => {
    var date1 = new Date(first)
    var date2 = new Date(second)
    var minutes = 60 * 1000
    var diffMinutes = Math.abs((date1.getTime() - date2.getTime()) / minutes)
    return diffMinutes
}
const getToken = async () => {
    const orgToken = await localStorage.getItem('orgToken')

    if (orgToken && orgToken.token && orgToken.lastSaved) {
        const diffMinutes = dateDifference(Date.now(), orgToken.lastSaved)
    
        if (diffMinutes < 9) {
            return orgToken.token
        }
    }
    const org = await organisationLogin()
    saveTokenData(org.token)

    if (org.success === true && org.token) {
        saveTokenData(org.token)
        return org.token
    }
    return null
}
export { saveTokenData, getToken, }
