const appConfig = {
    apiPrefix: '/api',
    merchantAuthenticatedEntryPath: '/merchant/dashboard',
    individualAuthenticatedEntryPath: '/consumer/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: true,
}

export default appConfig
