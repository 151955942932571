import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { util } from 'constants/api.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { storage } from '../../constants/storage'
import { http } from 'constants/http'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getToken } from 'configs/token'
import {
    DIGITAL_PREFIX_PATH,
    MERCHANT_PREFIX_PATH,
} from 'constants/route.constant'
function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const token = await getToken()

            const resp = await http.post(
                `${util.camel1Prod}auth/eNairaConsumerWalletLogin`,
                values,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: token,
                        userId: `clickatell`,
                    },
                }
            )

            if (resp?.data?.data?.walletHolderLogin?.token) {
                const consumerAlias = resp.data.data.walletHolderLogin.alias
                const userToken = resp.data.data.walletHolderLogin.token

                const role = 'Individual'
                const passReset =
                    resp.data.data.walletHolderLogin.needsPasswordReset
                const info = {
                    firstname: resp.data.data.firstName,
                    lastname: resp.data.data.lastName,
                    middlename: resp.data.data.middleName,
                    phone: resp.data.data.phone,
                    tier: resp.data.data.tier,
                    nuban: resp.data.data.nuban,
                    email: resp.data.data.emailAddress,
                }
                // alert(passReset)
                storage.save('userToken', userToken)
                storage.save('authData', consumerAlias)
                storage.save('reset', passReset)
                storage.save('authority', role)
                storage.save('info', JSON.stringify(info))
                dispatch(onSignInSuccess(userToken))

                const userLogin = {
                    userId: values.userId,
                    userIdType: values.userIdType,
                    password: values.password,
                }

                storage.save('userLogin', JSON.stringify(userLogin))
                dispatch(
                    setUser({
                        avatar: resp?.info?.avatar || '',
                        alias: consumerAlias || '',
                        authority: role || '',
                        firstname: info.firstname || '',
                        lastname: info.lastname || '',
                        middlename: info.middlename || '',
                        tier: info.tier || '',
                        nuban: info.nuban || '',
                        email: info.email || '',
                        phone: info.phone || '',
                        password:
                            resp.data.data.walletHolderLogin
                                .needsPasswordReset || '',
                        userIdType: userLogin.userIdType, // Corrected spelling
                        userId: userLogin.userId,
                        userPassword: userLogin.password,
                    })
                )

                navigate(`${DIGITAL_PREFIX_PATH}/dashboard`)

                return {
                    status: 'success',
                    error: null,
                    message: 'Login Successful',
                }
            } else {
                // Login failed
                if (resp?.data?.error && resp?.data?.status === 401) {
                    // Invalid username or password
                    toast.error('Invalid username or password')
                } else {
                    toast.error(
                        resp?.data?.error || 'An error occurred during login'
                    )
                }

                return {
                    status: 'failed',
                    error:
                        resp?.data?.error || 'An error occurred during login',
                    message:
                        resp?.data?.error || 'An error occurred during login',
                }
            }
        } catch (e) {
            console.error(e)
            toast.error('Invalid username or password')

            return {
                status: 'error',
                message: 'An error occurred during login',
            }
        }
    }

    const merchantSignIn = async (values) => {
        try {
            const token = await getToken()
            const user_id = storage.get('userId')
            const resp = await http.post(
                `${util.camel1Prod}auth/eNairaMerchantWalletLogin`,
                values,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${token}`,
                        userId: `${user_id}`,
                    },
                }
            )

            if (resp?.data?.data?.login?.token) {
                let merchantAlias = resp?.data?.data?.login?.alias
                let userToken = resp?.data?.data?.login?.token
                let role = 'Merchant'
                let passReset = resp?.data?.data?.login?.needsPasswordReset
                let info = {
                    firstname: resp?.data?.dirFirstName,
                    lastname: resp?.data?.dirLasttName,
                    middlename: resp?.data?.dirMiddleName,
                    phone: resp?.data?.phone,
                    tier: resp?.data?.tier,
                    nuban: resp?.data?.nuban,
                    email: resp?.data?.emailAddress,
                }
                storage.save('userToken', userToken)
                storage.save('reset', passReset)
                storage.save('authority', role)
                storage.save('authData', merchantAlias)
                storage.save('info', JSON.stringify(info))
                dispatch(onSignInSuccess(userToken))
                const userLogin = {
                    userId: values.userId,
                    userIdType: values.userIdType,
                    password: values.password,
                }
                // alert(JSON.stringify(userLogin))
                storage.save('userLogin', JSON.stringify(userLogin))
                dispatch(
                    setUser({
                        avatar: resp?.info?.avatar || '',
                        alias: merchantAlias || '',
                        authority: role || '',
                        firstname: info?.firstname || '',
                        lastname: info?.lastname || '',
                        middleName: info?.middlename || '',
                        tier: info?.tier || '',
                        nuban: info?.nuban || '',
                        email: info?.email || '',
                        phone: info?.phone || '',
                        password:
                            resp?.data?.data?.login?.needsPasswordReset || '',
                        userIdType: userLogin.userIdType, // Corrected spelling
                        userId: userLogin.userId,
                        userPassword: userLogin.password,
                    })
                )
                navigate(`${MERCHANT_PREFIX_PATH}/dashboard`)
                return {
                    status: 'success',
                    error: null,
                    message: 'Login Successfully...',
                }
            } else {
                if (resp?.data?.error && resp?.data?.status === 401) {
                    // Invalid username or password
                    toast.error('Invalid username or password')
                } else {
                    toast.error(
                        resp?.data?.error || 'An error occurred during login'
                    )
                }

                return {
                    status: 'failed',
                    error:
                        resp?.data?.error || 'An error occurred during login',
                    message:
                        resp?.data?.error || 'An error occurred during login',
                }
            }
        } catch (e) {
            console.error(e)

            toast.error('Invalid username or password')

            return {
                status: 'error',
                message: 'An error occurred during login',
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        storage.remove('userToken')
        storage.remove('authData')
        storage.remove('orgToken')
        storage.remove('orgToken3d')
        storage.remove('admin')
        storage.remove('userId')
        storage.remove('userId3d')
        storage.remove('preference')
        storage.remove('reset')
        storage.remove('accessKey')
        storage.remove('info')
        storage.remove('FeatureFlags')
        storage.remove('orgTokenLive')
        storage.remove('userLogin')
        storage.remove('authority')
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        merchantSignIn,
        signUp,
        signOut,
    }
}

export default useAuth
