import { http } from 'constants/http'
import { util } from 'constants/api.constant'
// import { saveTokenData } from 'configs/token'

const organisationLogin = async () => {
    try {
        const resp = await http.get(`${util.baseUrl}auth/organisation-token`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        let orgToken = resp.data.token
        let userId = resp.data.data.data.userid
        localStorage.setItem('userId', userId)
        localStorage.setItem('orgToken', orgToken)

        return {
            success: true,
            message: 'token fetched successfully',
            token: resp.data.token,
            userId: resp.data.data.userid,
        }
    } catch (error) {
        console.error(error)
        return {
            success: false,
            message: error?.response?.data?.error,
        }
    }
}

export default organisationLogin
