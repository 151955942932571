import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useConfig } from '../ConfigProvider'
import { CgSpinner } from 'react-icons/cg'

const Spinner = React.forwardRef((props, ref) => {
    const {
        className,
        color,
        enableTheme,
        indicator: Component,
        isSpining,
        size,
        style,
        image,
        ...rest
    } = props

    const { themeColor, primaryColorLevel } = useConfig()

    const spinnerColor =
        color || (enableTheme && `${themeColor}-${primaryColorLevel}`)

    const spinnerStyle = {
        height: size,
        width: size,
        ...style,
    }

    const spinnerClass = classNames(
        isSpining && 'animate-spin',
        spinnerColor && `text-${spinnerColor}`,
        className
    )

    return (
        <div style={{ position: 'relative' }}>
            <Component
                ref={ref}
                style={spinnerStyle}
                className={spinnerClass}
                {...rest}
            />
            {image && (
                <img
                    src={image}
                    alt="loader"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                    }}
                />
            )}
        </div>
    )
})

Spinner.defaultProps = {
    indicator: CgSpinner,
    isSpining: true,
    size: 40,
    enableTheme: true,
}

Spinner.propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    indicator: PropTypes.elementType,
    isSpining: PropTypes.bool,
    enableTheme: PropTypes.bool,
    image: PropTypes.string,
}

export default Spinner
